import type { AgentConfig } from '@kanbu/schema';
import { CapabilitiesPreset, ChatModel, Role } from '@kanbu/schema/enums';
import { AgentDefaults } from '@kanbu/shared';
import { useLingui } from '@lingui/react/macro';
import type { TypedFormState } from '@utima/ui-informed';
import { memo } from 'react';

import { Card } from '@/components/card/Card';
import { optionsFromEnum } from '@/components/form/formUtils';
import { SchemaFields } from '@/components/form/SchemaFields';
import { useFieldsSchema } from '@/components/form/useFieldsSchema';
import { useAccess } from '@/hooks/useAccess';
import { useEnumTranslations } from '@/locale/enumTranslations';

export type SuperAdminFieldsProps = {};

export const SuperAdminFields = memo(
  function SuperAdminFields({}: SuperAdminFieldsProps) {
    const { t } = useLingui();
    const { hasAccess } = useAccess();
    const translations = useEnumTranslations();

    const { schema } = useFieldsSchema(
      [
        {
          name: 'agent',
          visible: hasAccess([Role.SuperAdmin]),
          label: t`Agent`,
          type: 'select',
          options: translations.agent,
        },
        {
          name: 'agentConfig.capabilities',
          visible: hasAccess([Role.SuperAdmin]),
          label: t`Further specifications`,
          type: 'text',
          uiProps: {
            rows: 5,
          },
          uiControl: 'textarea',
          required: true,
          defaultValue:
            translations.capabilities[CapabilitiesPreset.CustomerSupport],
        },
        {
          name: 'agentConfig.smallModel',
          visible: hasAccess([Role.SuperAdmin]),
          label: 'smallModel',
          type: 'select',
          options: optionsFromEnum(ChatModel),
          required: true,
          defaultValue: ChatModel.Gpt4oMini,
        },
        {
          name: 'model',
          visible: hasAccess([Role.SuperAdmin]),
          label: t`Model`,
          type: 'select',
          options: optionsFromEnum(ChatModel),
        },
        {
          name: 'agentConfig.systemPrompt',
          visible: hasAccess([Role.SuperAdmin]),
          label: t`System prompt`,
          type: 'text',
          uiProps: {
            rows: 10,
          },
          uiControl: 'textarea',
        },
        {
          name: 'agentConfig.hasWebSearch',
          label: t`Web search`,
          type: 'checkbox',
          uiControl: 'switch',
          defaultValue: false,
          visible: hasAccess([Role.SuperAdmin]),
        },
        {
          $relevant: {
            $when: ({ formState }) =>
              !!(formState as TypedFormState<{ agentConfig: AgentConfig }>)
                .values?.agentConfig?.hasWebSearch,
            $fields: [
              {
                name: 'agentConfig.webSearchUrls',
                label: t`Web search URLs`,
                type: 'text',
                uiProps: {
                  rows: 5,
                },
                uiControl: 'textarea',
                parser: value => {
                  if (typeof value === 'string') {
                    return value
                      .split('\n')
                      .map(line => line.trim())
                      .filter(line => line !== '');
                  }

                  return value;
                },
                mask: value => {
                  if (Array.isArray(value)) {
                    return value.map((item: string) => `${item} \n`).join('');
                  }

                  return value;
                },
              },
            ],
          },
        },
        {
          name: 'agentConfig.imageSupport',
          visible: hasAccess([Role.SuperAdmin]),
          label: t`Image support`,
          type: 'checkbox',
          uiControl: 'switch',
          defaultValue: false,
        },
        {
          name: 'agentConfig.hybridAlpha',
          visible: hasAccess([Role.SuperAdmin]),
          label: 'hybridAlpha',
          tooltip:
            'An alpha of 1 is a pure vector search. An alpha of 0 is a pure keyword search.',
          type: 'number',
          defaultValue: 0.7,
        },
        {
          name: 'agentConfig.rewriteRetries',
          visible: hasAccess([Role.SuperAdmin]),
          label: 'rewriteRetries',
          type: 'number',
          defaultValue: 1,
        },
        {
          name: 'agentConfig.historyLimit',
          visible: hasAccess([Role.SuperAdmin]),
          label: 'historyLimit',
          type: 'number',
          defaultValue: 8,
        },
        {
          name: 'agentConfig.contextHistoryLimit',
          visible: hasAccess([Role.SuperAdmin]),
          label: 'contextHistoryLimit',
          type: 'number',
          defaultValue: 8,
        },
        {
          name: 'agentConfig.retrievalCount',
          visible: hasAccess([Role.SuperAdmin]),
          label: 'retrievalCount',
          type: 'number',
          defaultValue: 50,
        },
        {
          name: 'agentConfig.rerank',
          visible: hasAccess([Role.SuperAdmin]),
          label: 'rerank',
          type: 'checkbox',
          uiControl: 'switch',
          defaultValue: true,
        },
        {
          $relevant: {
            $when: ({ formState }) =>
              !!(formState as TypedFormState<{ agentConfig: AgentConfig }>)
                .values?.agentConfig?.rerank,
            $fields: [
              {
                name: 'agentConfig.rerankCount',
                visible: hasAccess([Role.SuperAdmin]),
                label: 'rerankCount',
                type: 'number',
                defaultValue: 20,
              },
              {
                name: 'agentConfig.rerankWeight',
                visible: hasAccess([Role.SuperAdmin]),
                label: 'rerankWeight',
                type: 'number',
                defaultValue: 0.7,
              },
            ],
          },
        },
        {
          name: 'agentConfig.minScore',
          visible: hasAccess([Role.SuperAdmin]),
          label: 'minScore',
          type: 'number',
          defaultValue: 0,
        },
        {
          name: 'chatbotConfig.maxCharacters',
          label: t`Max characters`,
          visible: hasAccess([Role.SuperAdmin]),
          type: 'number',
          showOptional: false,
        },
        {
          name: 'chatbotConfig.bubbleDescription',
          label: t`Bubble description`,
          visible: hasAccess([Role.SuperAdmin]),
          showOptional: false,
        },
        {
          name: 'themeConfig.secondary',
          label: 'secondary',
          visible: hasAccess([Role.SuperAdmin]),
          uiProps: {
            type: 'color',
          },
          defaultValue: AgentDefaults.defaultTheme.secondary,
        },
        {
          name: 'themeConfig.secondaryFg',
          label: 'secondaryFg',
          visible: hasAccess([Role.SuperAdmin]),
          uiProps: {
            type: 'color',
          },
          defaultValue: AgentDefaults.defaultTheme.secondaryFg,
        },
        {
          name: 'themeConfig.backgroundSecondary',
          label: 'backgroundSecondary',
          visible: hasAccess([Role.SuperAdmin]),
          uiProps: {
            type: 'color',
          },
          defaultValue: AgentDefaults.defaultTheme.backgroundSecondary,
        },
        {
          name: 'themeConfig.background',
          label: 'background',
          visible: hasAccess([Role.SuperAdmin]),
          uiProps: {
            type: 'color',
          },
          defaultValue: AgentDefaults.defaultTheme.background,
        },
        {
          name: 'themeConfig.foreground',
          visible: hasAccess([Role.SuperAdmin]),
          label: 'foreground',
          uiProps: {
            type: 'color',
          },
          defaultValue: AgentDefaults.defaultTheme.foreground,
        },
        {
          name: 'themeConfig.placeholder',
          visible: hasAccess([Role.SuperAdmin]),
          label: 'placeholder',
          uiProps: {
            type: 'color',
          },
          defaultValue: AgentDefaults.defaultTheme.placeholder,
        },
      ],
      [t, hasAccess],
    );

    return (
      <Card title={t`Superadmin configuration`} collapsed variant='secondary'>
        <SchemaFields schema={schema} />
      </Card>
    );
  },
);
