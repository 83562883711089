import { z } from 'zod';

import { ContentType } from '../enums';

export const ToolNames = {
  RagResult: 'rag_result',
  RagResultOperator: 'rag_result_operator',
} as const;

export const ragResultToolDef = z.object({
  content: z
    .string()
    .describe(
      'Main response with factual information from documents, formatted in markdown',
    ),
  sources: z
    .array(
      z.object({
        type: z.nativeEnum(ContentType).describe('Source document type'),
        url: z.string().describe('Document URL from metadata'),
        fileName: z
          .string()
          .describe(
            'Prettified source document file name from metadata. Remove underscores and capitalize. Drop file extensions.',
          ),
        page: z
          .string()
          .describe(
            'Number of page in the document on which the information was found. The page number is stored in the metadata.',
          ),
      }),
    )
    .optional()
    .describe('List of source documents referenced in the response'),
  answered: z.boolean().describe('Whether the question was answered'),
});

export const ragResultToolOperator = z.object({
  content: z
    .string()
    .describe(
      'natural conversationally response to the user, use the chat history to form the response',
    ),
  operator: z
    .object({
      name: z.string().describe('Operator name'),
      email: z.string().describe('Operator email'),
      phone: z.string().describe('Operator phone'),
    })
    .optional()
    .describe('Operator contact information'),
});

export type RagResultToolDef = z.infer<typeof ragResultToolDef>;
export type RagResultToolOperator = z.infer<typeof ragResultToolOperator>;
