import type { ChatType } from '@kanbu/schema';
import {
  CapabilitiesPreset,
  TonePreset,
  ResponseLengthPreset,
  AgentType,
} from '@kanbu/schema/enums';
import { AgentDefaults } from '@kanbu/shared';
import { useLingui } from '@lingui/react/macro';
import { useFormContext } from '@utima/ui-informed';
import { useFormApi, type FieldState } from 'informed';

import { Card } from '@/components/card/Card';
import { SchemaFields } from '@/components/form/SchemaFields';
import { useFieldsSchema } from '@/components/form/useFieldsSchema';
import { useAccess } from '@/hooks/useAccess';
import { useEnumTranslations } from '@/locale/enumTranslations';
import { useBoundStore } from '@/store/store';

export function BasicConfigFields() {
  const { hasAccess } = useAccess();
  const { t } = useLingui();
  const { initialValues } = useFormContext<ChatType>();
  const translations = useEnumTranslations();
  const formApi = useFormApi();
  const { user } = useBoundStore(state => ({
    user: state.user,
  }));

  const defaultCompanyName = user?.organization.name ?? 'Kanbu';
  const { tierConfig } = user?.organization ?? {};

  const { schema: detailsSchema } = useFieldsSchema(
    [
      {
        name: 'name',
        label: t`Name`,
        required: true,
        defaultValue: AgentDefaults.name,
      },
      {
        name: 'agentType',
        label: t`Agent type`,
        type: 'select',
        options: {
          [AgentType.Chat]: translations.agentType.chat,
          ...(!!tierConfig?.voiceBot && {
            [AgentType.Voice]: translations.agentType.voice,
          }),
        },
        defaultValue: AgentType.Chat,
        required: true,
      },
      {
        name: 'agentName',
        label: t`Agent name`,
        defaultValue: AgentDefaults.name,
        required: true,
      },
      {
        name: 'agentConfig.category',
        label: t`Focus`,
        type: 'select',
        options: translations.capabilitiesPreset,
        defaultValue: CapabilitiesPreset.CustomerSupport,
        showOptional: false,
        uiProps: {
          onChange: ({ value }: FieldState) => {
            formApi.setValue(
              'agentConfig.capabilities',
              translations.capabilities[value as CapabilitiesPreset],
            );
          },
        },
      },
      {
        name: 'agentConfig.tonePreset',
        label: t`Tone`,
        type: 'select',
        options: translations.tonePreset,
        defaultValue: TonePreset.Casual,
        showOptional: false,
      },
      {
        name: 'agentConfig.responseLengthPreset',
        label: t`Response length`,
        type: 'select',
        options: translations.responseLengthPreset,
        defaultValue: ResponseLengthPreset.Concise,
        showOptional: false,
      },
      {
        name: 'agentConfig.language',
        label: t`Language`,
        type: 'select',
        options: {
          automatic: t`Automatic`,
          ...translations.supportedLanguages,
        },
        defaultValue: 'automatic',
        showOptional: false,
      },
      {
        name: 'agentConfig.companyName',
        label: t`Company name`,
        defaultValue: defaultCompanyName,
        required: true,
      },
      {
        name: 'agentConfig.companyDescription',
        label: t`Company description`,
        type: 'text',
        uiProps: {
          rows: 5,
        },
        uiControl: 'textarea',
        required: true,
        defaultValue: t`Company description for ${defaultCompanyName}`,
      },
      {
        name: 'agentConfig.showSources',
        label: t`Show sources`,
        type: 'checkbox',
        uiControl: 'switch',
        defaultValue: true,
        required: true,
      },
      {
        name: 'agentConfig.showFollowUpQuestions',
        label: t`Show follow-up questions`,
        type: 'checkbox',
        uiControl: 'switch',
        tooltip: t`Disabling this option will hide follow-up questions.`,
        defaultValue: true,
        required: true,
      },
    ],
    [t, hasAccess, initialValues],
  );

  return (
    <Card title={t`Basic details`}>
      <SchemaFields schema={detailsSchema} />
    </Card>
  );
}
